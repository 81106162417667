const Dashboard = () => import('@pages/Dashboard.vue')
const Error404 = () => import('@pages/Error404.vue')
const Error500 = () => import('@pages/Error500.vue')
// const Profile = () => import('@pages/Profile.vue')
const Produk = () => import('@pages/Produk.vue')
const PengawasanVM = () => import('@pages/PengawasanVM.vue')
const InformasiVM = () => import('@pages/InformasiVM.vue')
const InformasiKaryawan = () => import('@pages/InformasiKaryawan.vue')
const Transaksi =() => import('@pages/Transaksi.vue')


module.exports = [
    {
        path: '/',
        name: 'Dashboard',
        component: Dashboard,
    },
    {
        path: '/error404',
        name: 'error404',
        component: Error404
    }, {
        path: '/error500',
        name: 'error500',
        component: Error500
    }, {
        path: '/PengawasanVM',
        name: 'PengawasanVM',
        component: PengawasanVM,
    }, {
        path: '/InformasiVM',
        name: 'Master Data / Informasi VM',
        component: InformasiVM,
    }, {
        path: '/InformasiKaryawan',
        name: 'Master Data / Informasi Karyawan',
        component: InformasiKaryawan,
    }, {
        path: 'Produk',
        name: 'Master / Produk',
        component: Produk,
    }, {
        path: 'Transaksi',
        name: 'Transaksi',
        component: Transaksi,
    },
    
]
